<template>
  <div class="index">
    <img :src="data.url + 'bg_top.png'" alt="" class="bg1" />
    <img :src="data.url + 'bg_down.png'" alt="" class="bg2" />
    <div class="content">
      <div class="light"></div>
      <div class="aperture"></div>
      <img :src="data.url + 'logo.png'" alt="" class="logo" />
      <div class="menuRow slide-up" @click="jumpPath('brandStory')">
        <div class="box">
          <div class="coverImg">
            <img :src="data.url + 'pags.png'" alt="" />
          </div>
          <div class="boxTitle">
            <div class="titleText">品牌故事</div>
            <div class="des">梦开始的地方</div>
          </div>
        </div>
      </div>
      <div class="menuCol slide-up">
        <div class="colItem" @click="jumpPath('techno')">
          <div class="box flex-col">
            <div class="coverImg">
              <img :src="data.url + 'cxky.png'" alt="" />
            </div>
            <div class="boxTitle">
              <div class="titleText">创新科研</div>
              <div class="des">行业领先的底气</div>
            </div>
          </div>
        </div>
        <div class="colItem" @click="jumpPath('goodPower')">
          <div class="box flex-col">
            <div class="coverImg">
              <img :src="data.url + 'sdll.png'" alt="" />
            </div>
            <div class="boxTitle">
              <div class="titleText">善的力量</div>
              <div class="des">让世界更美好</div>
            </div>
          </div>
        </div>
      </div>
      <div class="menuRow slide-up" @click="jumpPath('starProduct')">
        <div class="box">
          <div class="coverImg">
            <img :src="data.url + 'mxcp.png'" alt="" />
          </div>
          <div class="boxTitle">
            <div class="titleText">明星产品</div>
            <div class="des">整全生活方式</div>
          </div>
        </div>
      </div>
      <div class="menuRow slide-up" @click="jumpPath('dealer')">
        <div class="box">
          <div class="boxTitle">
            <div class="titleText">事业活动</div>
            <div class="des">实现自我价值</div>
          </div>
          <div class="coverImg">
            <img
              :src="data.url + 'syhd.png'"
              style="border-radius: 0 0.08rem 0.08rem 0"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="moreProduct">更多精彩内容，敬请期待！</div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { baiduTotal } from "../js/common.js";
const router = useRouter();
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
});
//页面跳转
let jumpPath = (type) => {
  router.push({
    path: `/${type}`,
  });
};
onMounted(() => {
  baiduTotal();
});
</script>
<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  position: relative;
  background: #4c6895;
  padding: 1.54rem 0 0.36rem;
  box-sizing: border-box;
  overflow-y: auto;
  .bg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .bg2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .content {
    position: relative;
    width: 3.52rem;
    border-radius: 0.08rem;
    border: 0.02rem solid rgba($color: #bedfec, $alpha: 0.5);
    background: radial-gradient(
        at top center,
        rgba(122, 189, 228, 0.8),
        rgba(76, 104, 149, 0.7)
      ),
      radial-gradient(
        50% 50% at top,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
      );
    backdrop-filter: blur(5px);
    box-shadow: 0 0.14rem 0.3rem rgba($color: #7abde4, $alpha: 0.4);
    box-sizing: border-box;
    z-index: 1;
    font-size: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0.3rem 0.14rem 0;
    .light {
      position: absolute;
      width: 90%;
      height: 1rem;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 0.02rem solid;
      border-image: radial-gradient(
          at top center,
          rgba(228, 245, 252, 1),
          rgba(228, 245, 252, 0),
          rgba(228, 245, 252, 0)
        )
        2 2 2 2;
    }
    .aperture {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1.8rem;
      height: 0.1rem;
      background: radial-gradient(
        at top center,
        rgba(190, 223, 236, 1),
        rgba(190, 223, 236, 0),
        rgba(190, 223, 236, 0)
      );
      filter: blur(4px);
    }
    .c_bg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .logo {
      position: relative;
      height: 0.58rem;
      margin-bottom: 0.22rem;
    }
    .menuRow {
      position: relative;
      width: 100%;
      font-size: 0;
      border: 1px solid #e2f0f6;
      border-radius: 0.08rem;
      background: linear-gradient(140deg, #f9fdff, #e2f0f6);
      box-shadow: 0 0.04rem 0.06rem rgba($color: #f9fdff, $alpha: 0.3);
      margin-bottom: 0.1rem;
    }
    .menuCol {
      position: relative;
      width: 100%;
      height: 1.66rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
      .colItem {
        width: 1.55rem;
        height: 1.66rem;
        border: 1px solid #e2f0f6;
        border-radius: 0.08rem;
        background: linear-gradient(140deg, #f9fdff, #e2f0f6);
        box-shadow: 0 0.04rem 0.06rem rgba($color: #f9fdff, $alpha: 0.3);
      }
    }
    .box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .coverImg {
        width: 1.6rem;
        padding: 1px;
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }
      .boxTitle {
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .titleText {
          font-family: Source Han Serif CN, Source Han Serif CN;
          font-weight: 800;
          font-size: 20px;
          color: #4c6895;
          line-height: 0.3rem;
          margin-bottom: 0.04rem;
        }
        .des {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #4c6895;
          line-height: 0.18rem;
        }
      }
    }
    .flex-col {
      flex-direction: column;
      .coverImg {
        width: 100%;
        height: 0.92rem;
        img {
          height: 100%;
          border-radius: 0.08rem 0.08rem 0 0;
        }
      }
      .boxTitle {
        width: 100%;
        height: 0;
      }
    }
    .moreProduct {
      position: relative;
      width: 100%;
      color: #fff;
      text-align: center;
      font-family: Source Han Serif SC;
      font-size: 0.14rem;
      font-style: normal;
      font-weight: 900;
      margin-top: 0.1rem;
      margin-bottom: 0.2rem;
    }
  }
  .bg_2 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.slide-up {
  animation: slide-up 2s forwards;
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
